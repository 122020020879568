body {
  --g-spacing: 20px;

  background-color: #000000;
  background: #000 url(assets/img/bg_dark.png) top left / cover;
  padding: var(--g-spacing) 0px;
}

a {
  color: #fff;
}

.App {
  min-height: calc(100vh - var(--g-spacing) * 2);
  font-size: 1em;
  color: #adadad;
}

.App-header {
  text-align: center;
}

.App-header img {
  margin: 0px var(--g-spacing) var(--g-spacing) var(--g-spacing);
  max-width: 100%;
  max-height: 100%;
}

.App-members {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 10px;
  margin-top: var(--g-spacing);
}

.App-highlights {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 10px;
}

.App-spinner {
  display: block;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .App-members {
    margin: 0px var(--g-spacing) var(--g-spacing) var(--g-spacing);
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .App-highlights {
    margin: 0px var(--g-spacing) var(--g-spacing) var(--g-spacing);
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}
