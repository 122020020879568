.Highlight {
  background: #191919;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}

.Highlight-value {
  font-size: 2.5em;
}
