.Member {
  background: #191919;
}

.Member-error {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.Member-header, .Member-property {
  padding: 10px;
}

.Member-header {
  padding: 10px;
  border-bottom: solid 3px #3c3c3c;
  font-weight: bold;
  font-size: 1.1em;
}

.Member > .Member-property + .Member-property {
  border-top: solid 1px #3c3c3c;
}

.Member-property {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9em;
  color: #dcdcdc;
}
